<template>
  <section class="awareness-panel-section">
    <template v-for="link in links" :key="link.name">
      <awareness-panel-item :item-class="link.itemClass"
                            :lang-key="link.langKey"
                            :left-icon="link.leftIcon"
                            :url="link.url"
                            :with-appended-arrow="link.withAppendedArrow || false" />
    </template>
  </section>
</template>

<script>
import AwarenessPanelItem from '_acaSrc/components/homePageRedesign/AwarenessPanelItem.vue';
import {mapGetters} from 'vuex';

export default {
    name: 'HomeSearchAwarenessPanel',
    components: { AwarenessPanelItem },
    computed: {
        ...mapGetters('app', [ 'isUccState' ]),
        ...mapGetters('feature', [ 'isShowRxTransitions' ]),
        links() {
            const links = [
                {
                    langKey: 'HEADER.DRUG_INTERACTIONS',
                    url: '/drug-interactions/?source=responsive_home#di-druglist',
                    leftIcon: 'drug-interactions',
                    withAppendedArrow: false,
                    itemClass: 'drug-interactions'
                },
                {
                    langKey: 'HEADER.PRACTICE_CHANGING_UPDATES',
                    url: '/contents/practice-changing-updates',
                    leftIcon: 'practice-changing-updates',
                    withAppendedArrow: false,
                    itemClass: 'practice-changing-updates'
                },
                {
                    langKey: 'HEADER.WHATS_NEW',
                    url: '/contents/table-of-contents/whats-new',
                    leftIcon: 'whats-new-by-specialty',
                    withAppendedArrow: false,
                    itemClass: 'whats-new'
                },
                {
                    langKey: 'HEADER.PATIENT_EDUCATION',
                    url: '/contents/table-of-contents/patient-education',
                    leftIcon: 'patient-education',
                    withAppendedArrow: false,
                    itemClass: 'patient-education'
                },
                {
                    langKey: 'HEADER.CALCULATORS',
                    url: '/contents/table-of-contents/calculators/categorized',
                    leftIcon: 'calculators',
                    withAppendedArrow: false,
                    itemClass: 'calculators'
                }
            ];

            if(!this.isUccState && this.isShowRxTransitions) {
                links.push({
                    langKey: 'HEADER.RX_TRANSITIONS',
                    url: '/rxtransitions?source=search__container',
                    leftIcon: 'rx-transitions-for-mental-health',
                    withAppendedArrow: false,
                    itemClass: 'rx-transitions-for-mental-health'
                });
            }

            return links;
        }
    }
};
</script>

<style scoped lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.awareness-panel-section {
  display: flex;
  flex-direction: column;
  width: 630px;
  margin: 32px auto 64px;
  justify-content: center;

  @media only screen and (max-width: 768px) {
    .ds1-ph-4();
    width: unset;
  }
}
</style>