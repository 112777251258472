<template>
  <div id="redesign-header" :class="{ isUcc: isUccState }">
    <div class="body-container">
      <div v-if="showRedesignedLogo" :class="isUccState ? 'utdcc-logo' : 'utd-logo'" />
      <utd-logo v-else :dark-logo="false" />
      <home-search-panel />
    </div>
  </div>
  <home-search-disclaimer />
  <home-search-awareness-panel />
  <MobileProspectFooter v-if="!isHeaderRedesign && !isDesktopView" />
</template>

<script>
import HomeSearchDisclaimer from '_acaSrc/components/homePageRedesign/HomeSearchDisclaimer.vue';
import HomeSearchPanel from '_acaSrc/components/homePageRedesign/HomeSearchPanel.vue';
import HomeSearchAwarenessPanel
    from '_acaSrc/components/homePageRedesign/HomeSearchAwarenessPanel.vue';
import UtdLogo from '_acaSrc/components/header/utdLogo/UtdLogo.vue';
import {mapGetters} from 'vuex';
import MobileProspectFooter from '_acaSrc/components/footer/MobileProspectFooter.vue';

export default {
    name: 'HomeSearchView',
    components: {
        HomeSearchAwarenessPanel,
        UtdLogo,
        HomeSearchPanel,
        MobileProspectFooter,
        HomeSearchDisclaimer
    },
    computed: {
        ...mapGetters('device', [ 'isDesktopView', 'deviceType' ]),
        ...mapGetters('feature', [ 'isHeaderRedesign' ]),
        ...mapGetters('app', [ 'isUccState' ]),
        showRedesignedLogo() {
            return this.isDesktopView || this.deviceType === 'tablet';
        }
    }
};
</script>

<style scoped lang="less">
@import (reference) '~_acaAssets/wkce/colors/wkce-app-styles';

.utdWkHomePage2024 #redesign-header {
  background: url('~_acaAssets/utd-redesign/blue-gradient-background.png') no-repeat;
  background-size: 100% 100%;

  .logo-properties {
    background-repeat: no-repeat;
    height: 62px;
    background-position-x: 50%;
    background-position-y: 50%;
  }

  .body-container {
    padding-top: clamp(30px, 5vh, 80px);
    padding-bottom: clamp(30px, 5vh, 80px);

    :deep(.wk-logo) {
      width: 100vw;
    }

    .isTablet& {
      :deep(.wk-logo) {
        width: 50vw;
        margin-left: auto;
        margin-right: auto;
      }
    }

    @media only screen and (max-width: 768px) {
      .ds1-ph-4();
      padding-bottom: 40px;
      width: unset;

      :deep(.wk-logo) {
        width: 50vw;
        margin-left: auto;
        margin-right: auto;
        margin-top: unset;
      }
    }

    :deep(#new-search) {
      display: block;
    }

    .utdcc-logo {
      background-image: url('~_acaAssets/utd-redesign/utdcc-logo.svg');
      .logo-properties();
    }

    .utd-logo {
      background-image: url('~_acaAssets/utd-redesign/utd-logo.svg');
      .logo-properties();
    }
  }

  .isSmallScreen&,
  .isMobile&,
  .isMobileOnDesktop&,
  .mobileonDesktop& {
    .body-container {
      padding-bottom: 40px;
    }

    .anonymous-user&, &.isUcc {
      .body-container {
        padding-bottom: 60px;
      }
    }
  }
}
</style>