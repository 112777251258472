import { C_BROWSER, C_APP, C_UI, C_LANGUAGES_NAMES, C_MY_ACCOUNT } from '_acaSrc/utility/constants';
import { processEventOptions } from '_acaSrc/utility/Events';
import PubSub from '_acaSrc/utility/PubSub';
import utdRestClient from '_acaSrc/utility/http/UtdRestClient';
import { getDocument, getWindow, isSmallScreen, isTablet } from '_acaSrc/utility/DOM';
import Logger from '_acaSrc/utility/Logger';
import {
    getRelativeUrl,
    makeRelativeUrl,
    getQueryParamValues,
    setQueryParamValue
} from '_acaSrc/utility/http';
import UtdCache from '_acaSrc/utility/UtdCache';
import i18next from 'i18next';
import { version } from '../../package';
import IndexManager from '_acaSrc/utility/IndexManager';

import {
    SET_MYUPTODATE_PERMISSIONS,
    SET_MYUPTODATE_MESSAGE,
    SET_WELCOME_NAME,
    SET_USER_EMAIL,
    SET_OIDC_LOGIN_HINT,
    SET_KEEP_ONE_ACCOUNT_REQUEST,
    SET_IS_MY_UTD_VIEW
} from '_acaSrc/store/profile.store';
import {
    SET_LOGIN_SUBSCRIBER_BOX,
    SET_LOGIN_REGISTER_BUTTON,
    SET_RENEWAL_SUBSCRIPTION_URL,
    SET_LOGIN_ERROR_MESSAGE
} from '_acaSrc/store/login.store';
import { SET_ERROR_CLASS } from '_acaSrc/store/error.store';
import {
    SET_FONT_SIZE_PREFERENCE,
    SET_HIDE_MARKETING_BANNER,
    SET_IS_TOPIC_VIEW
} from '_acaSrc/store/topic/topic.store';
import { SET_IS_GRAPHIC_VIEW, SET_IS_PRINT_GRAPHIC } from '_acaSrc/store/graphic.store';
import { SET_IS_QUICK_ACCESS_PANEL, SET_IS_UCC_QUICK_ACCESS_PANEL } from '_acaSrc/store/qap.store';
import {
    SET_SEARCHBAR_BODY,
    SET_SEARCHBAR_HEADER,
    SET_SEARCHBAR_AUTOCOMPLETE,
    SET_SEARCHBAR_DISABLE_AC,
    SET_IS_GRAPHICAL_ANSWERS,
    SET_IS_SEARCH_VIEW,
    SET_IS_HOME_SEARCH_EMPTY,
    SET_SEARCH_RESULT_ALTERNATE,
    SET_IS_GRAPHIC,
    SET_SEARCH_RESULTS_STATE
} from '_acaSrc/store/search.store';
import ServerMessaging from '_acaSrc/utility/ServerMessaging';
import {
    SET_SHOW_SKIP_LINK,
    SET_FORMULINK_CONTENT,
    SET_IS_HEADER_REDESIGN,
    SET_IS_HOMEPAGE_REDESIGN,
    SET_HAS_CONTENT_SHARE,
    SET_GUEST_PASS,
    SET_TOPIC_REDESIGN,
    SET_SHOW_LAB_INTERPRETATION,
    SET_SHOW_RX_TRANSITIONS,
    SET_LOGIN_REDESIGN_ENABLED,
    SET_TWO_FACTOR_EMAIL_ENABLED
} from '_acaSrc/store/feature.store';
import { SET_CME_AUTO_SUBMIT, SET_MOC_BOARD_URLS } from '_acaSrc/store/cme.store';
import {
    SET_SSO_ACCOUNT_NAME,
    SET_ODIC_PARTNER_DETAILS,
    SET_EXPIRED_SITE
} from './auth.store';
import {
    SET_DIALOGUE_LANGUAGE,
    SET_EXPIRATION_STATUS
} from './user.store';

const NULL_LAST_CONTENT_UPDATE = -1;

const PERF_COOKIE_ONETRUST_GRP_ID = '2';

const state = {
    pubsub: new PubSub(),
    router: {},
    transitions: {},
    utdAuthService: {},
    routeLoading: '',
    cobranding: {
        enabled: false,
        imageUrl: '',
        anchorHref: ''
    },
    executeAfterLoad: [],
    lastContentUpdate: NULL_LAST_CONTENT_UPDATE,
    isAppDataLoaded: false,
    googleAnalytics: {
        isRecord: false,
        info: ''
    },
    isUserRegistered: false,
    storedSettings: {
        textSize: 'textSize-M'
    },
    product: {
        name: C_APP.PRODUCT_NAMES.UTD,
        contentVersion: '',
        releaseVersion: '',
        applicationVersion: '',
        countryCode: ''
    },
    destinationUrl: '',
    routePathname: '',
    isProspectChina: false,
    isUccState: false,
    metaDescription: C_APP.META_DESCRIPTION,
    defaultMetaDescription: C_APP.META_DESCRIPTION,
    fixedHTML: '',
    isDefaultFixed: false,
    isAlwaysShowBackButton: false,
    canonicalUrl: '',
    additiveContent: {
        keys: [],
        classes: [],
        css: false
    },
    defaultPageLang: C_LANGUAGES_NAMES.EN.CODE,
    noHeaderClass: '',
    utdLogoFilename: 'utd-logo',
    isFixedToolbar: false,
    isLandingHome: false,
    hasHeader: true,
    isOneTrustEnabled: false,
    lockBodyScroll: false,
    iosBodyLockHide: false,
    isPrintView: false,
    clickEventUrls: [],
    printData: [],
    enabledPathways: [],
    disabledPathways: [],
    isFixedSidebar: false,
    hasPromoBox: false,
    hasSessionData: false,
    vueLoaded: '',
    uccCopyrightYear: '',
    copyright: {
        full: '',
        ucc: ''
    },
    cssStyleSheets: {
        medCalc: {
            url: '/contents/assets/css/medcalcstyles.css',
            cssLoaded: false
        },
        utdGxGen: {
            id: 'utdGxGen',
            url: '/contents/assets/css/UTD_gx_gen.css',
            cssLoaded: false
        }
    },
    ankiDownloadTemplate: '',
    hasInitializedGTag: false,
    isSpinnerVisible: false,
    lastUrlLoaded: '',
    myAccountSidebarRequestStatus: '',
    isDisasterRecovery: false
};

export const SET_ROUTER = 'SET_ROUTER';
export const SET_CONFIG = 'SET_CONFIG';
export const SET_TRANSITIONS = 'SET_TRANSITIONS';
export const SET_ROOT_SCOPE = 'SET_ROOT_SCOPE';
export const SET_BODY_SCROLL_LOCK = 'SET_BODY_SCROLL_LOCK';
export const SET_IOS_BODY_HIDE_LOCK = 'SET_IOS_BODY_HIDE_LOCK';
export const SET_UTD_UTIL_SERVICE = 'SET_UTD_UTIL_SERVICE';
export const SET_UTD_AUTH_SERVICE = 'SET_UTD_AUTH_SERVICE';
export const SET_SESSION_INFO_SERVICE = 'SET_SESSION_INFO_SERVICE';
export const SET_CONTENT_SERVICE = 'SET_CONTENT_SERVICE';
export const SET_USER_SERVICE = 'SET_USER_SERVICE';
export const SET_LAST_URL_LOADED = 'SET_LAST_URL_LOADED';
export const SET_ROUTE_LOADING = 'SET_ROUTE_LOADING';
export const SET_COBRANDING_DATA = 'SET_COBRANDING_DATA';
export const SET_APP_DATA_LOADED = 'SET_APP_DATA_LOADED';
export const ENQUEUE_AFTER_LOAD = 'ENQUEUE_AFTER_LOAD';
export const SET_ANALYTICS_INFO = 'SET_ANALYTICS_INFO';
export const SET_IS_USER_REGISTERED = 'SET_IS_USER_REGISTERED';
export const REFRESH_STORED_SETTINGS = 'REFRESH_STORED_SETTINGS';
export const SET_STORED_SETTING = 'SET_STORED_SETTING';
export const SET_DESTINATION_URL = 'SET_DESTINATION_URL';
export const SET_ROUTE_PATHNAME = 'SET_ROUTE_PATHNAME';
export const SET_IS_PROSPECT_CHINA = 'SET_IS_PROSPECT_CHINA';
export const SET_META_DESCRIPTION = 'SET_META_DESCRIPTION';
export const SET_IS_UCC_STATE = 'SET_IS_UCC_STATE';
export const SET_UCC_STATE = 'SET_UCC_STATE';
export const SET_CANONICAL_URL = 'SET_CANONICAL_URL';
export const SET_FIXED_HTML = 'SET_FIXED_HTML';
export const SET_IS_DEFAULT_FIXED = 'SET_IS_DEFAULT_FIXED';
const SET_ALWAYS_SHOW_BACK_BUTTON = 'SET_ALWAYS_SHOW_BACK_BUTTON';
const SET_ADDITIVE_CONTENT = 'SET_ADDITIVE_CONTENT';
export const SET_DEFAULT_PAGE_LANG = 'SET_DEFAULT_PAGE_LANG';
export const SET_NO_HEADER_CLASS = 'SET_NO_HEADER_CLASS';
export const SET_UTD_LOGO_FILENAME = 'SET_UTD_LOGO_FILENAME';
export const SET_IS_FIXED_TOOLBAR = 'SET_IS_FIXED_TOOLBAR';
export const SET_IS_LANDING_HOME = 'SET_IS_LANDING_HOME';
export const SET_HAS_HEADER = 'SET_HAS_HEADER';
export const SET_IS_PRINT_VIEW = 'SET_IS_PRINT_VIEW';
export const SET_ONE_TRUST_ENABLED = 'SET_ONE_TRUST_ENABLED';
export const SET_CLICK_EVENT_URLS = 'SET_CLICK_EVENT_URLS';
export const SET_PRINT_DATA = 'SET_PRINT_DATA';
export const SET_IS_FIXED_SIDEBAR = 'SET_IS_FIXED_SIDEBAR';
export const SET_HAS_PROMOBOX = 'SET_HAS_PROMOBOX';
export const SET_PATHWAYS_CONTENT_SETS = 'SET_PATHWAYS_CONTENT_SETS';
export const SET_PRODUCT_NAME = 'SET_PRODUCT_NAME';
export const SET_PRODUCT_VERSIONS = 'SET_PRODUCT_VERSIONS';
export const SET_COUNTRY_CODE = 'SET_COUNTRY_CODE';
export const SET_HAS_SESSION_DATA = 'SET_HAS_SESSION_DATA';
export const SET_VUE_LOADED = 'SET_VUE_LOADED';
export const SET_LAST_CONTENT_UPDATE = 'SET_LAST_CONTENT_UPDATE';
export const SET_UCC_COPYRIGHT_YEAR = 'SET_UCC_COPYRIGHT_YEAR';
export const SET_COPYRIGHT_HTML = 'SET_COPYRIGHT_HTML';
export const SET_GRAPHICS_STYLES_LOADED = 'SET_GRAPHICS_STYLES_LOADED';
export const SET_MEDCALC_STYLES_LOADED = 'SET_MEDCALC_STYLES_LOADED';
export const SET_ANKI_TEMPLATE = 'SET_ANKI_TEMPLATE';
export const HAS_INITIALIZED_GTAG = 'HAS_INITIALIZED_GTAG';
export const SET_IS_SPINNER_VISIBLE = 'SET_IS_SPINNER_VISIBLE';
export const SET_MYACCOUNT_SIDEBAR_REQUEST = 'SET_MYACCOUNT_SIDEBAR_REQUEST';
export const SET_IS_DISASTER_RECOVERY = 'SET_IS_DISASTER_RECOVERY';

export const mutations = {
    [SET_ROUTER]: (state, router) => state.router = router,
    [SET_TRANSITIONS]: (state, transitions) => state.transitions = transitions,
    [SET_ROOT_SCOPE]: (state, rootScope) => state.rootScope = rootScope,
    [SET_BODY_SCROLL_LOCK]: (state, isLocked) => state.lockBodyScroll = isLocked,
    [SET_IOS_BODY_HIDE_LOCK]: (state, isHidden) => state.iosBodyLockHide = isHidden,
    [SET_UTD_AUTH_SERVICE]: (state, utdAuthService) => state.utdAuthService = utdAuthService,
    [SET_ROUTE_LOADING]: (state, routeName) => state.routeLoading = routeName,
    [SET_LAST_URL_LOADED]: state => state.lastUrlLoaded = getWindow().location.href,
    [SET_COBRANDING_DATA]: (state, data) => {
        if (!data || !data.cobrandingUrls) {
            return;
        }

        state.cobranding.enabled = data.cobrandingEnabled;
        state.cobranding.imageUrl = data.cobrandingUrls.cobrandingLogoImageUrl;
        state.cobranding.anchorHref = data.cobrandingUrls.cobrandingLogoLink;
    },
    [SET_APP_DATA_LOADED]: state => state.isAppDataLoaded = true,
    [ENQUEUE_AFTER_LOAD]: (state, fn) => state.executeAfterLoad.push(fn),
    [SET_ANALYTICS_INFO]: (state, data) => {
        if ('isRecordGoogleAnalytics' in data) {
            state.googleAnalytics.isRecord = data.isRecordGoogleAnalytics;
        }
        if ('googleAnalytics' in data) {
            state.googleAnalytics.info = data.googleAnalytics;
            /* TODO: HACK HACK HACK HACK - REMOVE THIS FOR 26.3 */
            /* This is to ensure that GA is tracked for /logout */
            /* prior to 26.3 as we cannot modify the blob entry */
            if (state.googleAnalytics && state.googleAnalytics.info
                && state.googleAnalytics.recordUrls
                && state.googleAnalytics.info.recordUrls.indexOf('/logout') < 0) {
                state.googleAnalytics.info.recordUrls.push('/logout');
            }
        }
    },
    [SET_IS_USER_REGISTERED]: (state, data) => {
        state.isUserRegistered = false;
        if ('isRegistered' in data) {
            state.isUserRegistered = data.isRegistered;
        }
    },
    [REFRESH_STORED_SETTINGS]: state => {
        const utdCacheSvc = new UtdCache();
        if (utdCacheSvc.cookiesDisabled) {
            return;
        }
        const lsSettings = utdCacheSvc.getPersistent('utdSPAStoredSettings');
        if (!lsSettings) {
            return;
        }
        try {
            state.storedSettings = lsSettings;
        }
        catch (ex) {
            // Not logging these errors to the server
            // eslint-disable-next-line no-console
            console.error(`app.store::REFRESH_STORED_SETTINGS - JSON parse error - ${ex}`);
        }
    },
    [SET_STORED_SETTING]: (state, payload) => {
        const { key, value } = payload;
        state.storedSettings[key] = value;
        new IndexManager().bodyCss.set(key, value);
        const storedSettings = JSON.stringify(state.storedSettings);
        new UtdCache().setPersistent('utdSPAStoredSettings', storedSettings, 365);
    },
    [SET_DESTINATION_URL]: (state, url) => {
        // This is the default and where the user goes before logging in, so just skip it
        if (url === '/contents/search') {
            return;
        }

        const result = C_APP.DESTINATION_URLS.some(function(regexp) {
            return regexp.test(url);
        });
        if (result) {
            state.destinationUrl = makeRelativeUrl(url);
            new UtdCache().setPersistent('utdDestinationUrl', state.destinationUrl, 1);
        }
    },
    [SET_ROUTE_PATHNAME]: state => {
        state.routePathname = getWindow().location.pathname;
    },
    [SET_IS_PROSPECT_CHINA]: (state, data) => {
        if ('isProspectChina' in data) {
            state.isProspectChina = data.isProspectChina;
        }
    },
    [SET_META_DESCRIPTION]: (state, meta) => state.metaDescription = meta,
    [SET_IS_UCC_STATE]: (state, isUcc) => state.isUccState = isUcc,
    [SET_UCC_STATE](state, isLoggedIn) {
        let isSetUccState = false;
        if (isLoggedIn) {
            if (state.product.name === C_APP.PRODUCT_NAMES.UCC) {
                isSetUccState = true;
            }
        }
        else if (state.product.countryCode === C_APP.COUNTRY_CODES.CHINA) {
            isSetUccState = true;
        }

        if (isSetUccState) {
            state.utdLogoFilename = C_APP.LOGO_FILENAMES.UCC;
            state.isUccState = true;
            this.commit(`topic/${SET_HIDE_MARKETING_BANNER}`, 'bannerClosed', { root: true });
            state.defaultPageLang = C_LANGUAGES_NAMES.ZH_HANS.CODE;
        }
    },
    [SET_CANONICAL_URL]: (state, canonicalUrl) => {
        state.canonicalUrl = canonicalUrl;
        new IndexManager().setCanonicalUrl(state.canonicalUrl);
    },
    [SET_FIXED_HTML]: (state, fixedHtml) => state.fixedHTML = fixedHtml,
    [SET_IS_DEFAULT_FIXED]: (state, isFixed) => state.isDefaultFixed = isFixed,
    [SET_ALWAYS_SHOW_BACK_BUTTON]: (state, data) => {
        state.isAlwaysShowBackButton = false;
        if ('isAlwaysShowBackButton' in data) {
            state.isAlwaysShowBackButton = data.isAlwaysShowBackButton;
        }
    },
    [SET_ADDITIVE_CONTENT]: (state, data) => {
        if (!('additiveContent' in data)) {
            return;
        }

        let strAc = '';
        for (const ac in data.additiveContent) {
            if (data.additiveContent.hasOwnProperty(ac)) {
                strAc += `${strAc.length > 0 ? ', ' : ''}.${data.additiveContent[ac]}`;

                // Store which additive content keys are present for this user's session
                state.additiveContent.keys[ac.toString()] = true;

                // Store actual authorized classes for lookup by Find-in-Topic
                state.additiveContent.classes.push(data.additiveContent[ac]);
            }
        }

        if (strAc.length > 0) {
            // Included !important to support mobile apps [REFM-2612]
            state.additiveContent.css = `${strAc} { display: inline-block !important; }`;
            state.additiveContent.css += `:is(${strAc}):not(.glyph):not(.plainItem) {
                display: inline !important;
            }`;
            state.additiveContent.css
                += `#searchresults li:is(${strAc}):not(.glyph):not(.plainItem) {
                    display: inherit !important;
                }`;
        }
    },
    [SET_DEFAULT_PAGE_LANG]: (state, defaultPageLang) => state.defaultPageLang = defaultPageLang,
    [SET_NO_HEADER_CLASS]: (state, noHeaderClass) => {
        state.noHeaderClass = noHeaderClass;
        new IndexManager().bodyCss.setOrClear('noHeaderClass', state.noHeaderClass);
    },
    [SET_UTD_LOGO_FILENAME]: (state, utdLogoFilename) => {
        state.utdLogoFilename = utdLogoFilename;
        new IndexManager().bodyCss.set('utdLogoFilename', state.utdLogoFilename);
    },
    [SET_IS_FIXED_TOOLBAR]: (state, isFixedToolbar) => {
        state.isFixedToolbar = isFixedToolbar;
        new IndexManager().bodyCss.setOrClear('fixedToolbar', state.isFixedToolbar);
    },
    [SET_IS_LANDING_HOME]: (state, value) => {
        state.isLandingHome = value;
        new IndexManager().bodyCss.setOrClear('isLandingHome', state.isLandingHome);
    },
    [SET_HAS_HEADER]: (state, value) => state.hasHeader = value,
    [SET_IS_PRINT_VIEW]: (state, isPrintView) => {
        state.isPrintView = isPrintView;
        new IndexManager().bodyCss.setOrClear('topicPrint', state.isPrintView);
    },
    [SET_ONE_TRUST_ENABLED]: (state, value) => state.isOneTrustEnabled = value,
    [SET_CLICK_EVENT_URLS]: (state, urlString) => {
        try {
            if (urlString) {
                state.clickEventUrls = urlString.split(',');
            }
        }
        catch (e) {
            // ignore, we just won't record some click events
        }
    },
    [SET_PRINT_DATA]: (state, value) => state.printData = value,
    [SET_IS_FIXED_SIDEBAR]: (state, value) => {
        state.isFixedSidebar = value;
        new IndexManager().bodyCss.setOrClear('fixedSidebar', state.isFixedSidebar);
    },
    [SET_HAS_PROMOBOX]: (state, hasPromoBox) => state.hasPromoBox = hasPromoBox,
    [SET_PATHWAYS_CONTENT_SETS]: (state, data) => {
        state.enabledPathways = data && data.enabledPathways || [];
        state.disabledPathways = data && data.disabledPathways || [];
    },
    [SET_PRODUCT_NAME]: (state, productName) => state.product.name = productName,
    [SET_PRODUCT_VERSIONS]: (state, data) => {
        state.product.contentVersion = data.appContentVersion;
        state.product.releaseVersion = data.appReleaseVersion;
        state.product.applicationVersion = version;
    },
    [SET_COUNTRY_CODE]: (state, value) => state.product.countryCode = value,
    [SET_HAS_SESSION_DATA]: (state, value) => {
        state.hasSessionData = value;
        new IndexManager().bodyCss.setOrClear('hasSessionData', state.hasSessionData);
    },
    [SET_VUE_LOADED]: (state, value) => {
        state.vueLoaded = value;
        new IndexManager().bodyCss.set(state.vueLoaded);
    },
    [SET_LAST_CONTENT_UPDATE]: (state, lastContentUpdate) => {
        state.lastContentUpdate = lastContentUpdate;
    },
    [SET_UCC_COPYRIGHT_YEAR]: (state, data) => {
        if ('uccCopyrightYear' in data) {
            state.uccCopyrightYear = data.uccCopyrightYear;
        }
    },
    [SET_COPYRIGHT_HTML]: state => {
        const curYear = new Date().getFullYear();

        state.copyright.full
            = `&copy; ${curYear} UpToDate, Inc. and/or its affiliates. All Rights Reserved.`;
        state.copyright.ucc
            = `${state.copyright.full} 京 ICP 证 110182 号 | (京)-经营性-${state.uccCopyrightYear}`;
    },
    [SET_GRAPHICS_STYLES_LOADED]: (state, isLoaded) => {
        state.cssStyleSheets.utdGxGen.cssLoaded = isLoaded;
    },
    [SET_MEDCALC_STYLES_LOADED]: (state, isLoaded) => {
        state.cssStyleSheets.medCalc.cssLoaded = isLoaded;
    },
    [SET_ANKI_TEMPLATE]: (state, value) => {
        state.ankiDownloadTemplate = value;
    },
    [HAS_INITIALIZED_GTAG]: (state, hasInitializedGTag) => {
        state.hasInitializedGTag = hasInitializedGTag;
    },
    [SET_IS_SPINNER_VISIBLE]: (state, visible) => {
        state.isSpinnerVisible = visible;
    },
    [SET_MYACCOUNT_SIDEBAR_REQUEST]: (state, value) => {
        state.myAccountSidebarRequestStatus = value;
    },
    [SET_IS_DISASTER_RECOVERY]: (state, data) => {
        if ('isDisasterRecovery' in data) {
            state.isDisasterRecovery = data.isDisasterRecovery;
        }
    }
};

export const actions = {
    setTransitions: ({ commit }, transitions) => {
        // There is a strange bug in PhantomJS where adding the entire ui-router
        // transitions service to the store causes phantom to crash. The silver
        // lining is this works as a bare-bones adapter-pattern wrapper.
        commit('SET_TRANSITIONS', {
            onBefore: transitions.onBefore,
            onStart: transitions.onStart,
            onSuccess: transitions.onSuccess,
            onFinish: transitions.onFinish
        });
    },
    // TODO - REPLACE WITH PUBSUB THEN DELETE
    broadcast: ({ state }, payload) => {
        // !!! DEPRECATED !!!
        // Do not use this for new code as it uses AngularJS
        // This should only be used to port old AngularJS code
        // Use the new publish/subscribe actions shown below
        let { eventName } = payload;
        const { params } = payload;
        if (!eventName) {
            eventName = payload;
        }
        state.rootScope.$broadcast(eventName, params);
    },
    confirmConfigLoaded({ getters, dispatch }) {
        return new Promise(resolve => {
            dispatch('delayExecuteUntilDataLoaded', () => {
                resolve(getters.isAppDataLoaded);
            });
        });
    },
    // TODO - MIGRATE TO USE COMPONENT VERSION THEN DELETE
    setListener({ rootGetters }, payload) {
        // !!! DEPRECATED !!!
        // Do not use this for new code
        // Instead use the setListener method available on all Vue components directly
        // See TopicSmoothScroller.vue component for usage example
        if (!payload) {
            return;
        }

        const options = processEventOptions(
            payload.options,
            rootGetters['device/supportsEventOptions']
        );

        const {
            eventTarget,
            eventType,
            eventHandler
        } = payload;

        if (eventTarget && eventType && eventHandler) {
            eventTarget.addEventListener(eventType, eventHandler, options);
        }
    },
    // TODO - MIGRATE TO USE COMPONENT VERSION THEN DELETE
    clearListener({ rootGetters }, payload) {
        // !!! DEPRECATED !!!
        // Do not use this for new code
        // Instead use the clearListener method available on all Vue components directly
        // See TopicSmoothScroller.vue component for usage example
        if (!payload) {
            return;
        }

        const options = processEventOptions(
            payload.options,
            rootGetters['device/supportsEventOptions']
        );

        const {
            eventTarget,
            eventType,
            eventHandler
        } = payload;

        if (eventTarget && eventType && eventHandler) {
            eventTarget.removeEventListener(eventType, eventHandler, options);
        }
    },
    // TODO - MIGRATE TO USE SINGLETON VERSION THEN DELETE
    publish({ getters }, payload) {
        getters.pubsub.publish(payload.eventName, payload.eventData);
    },
    // TODO - MIGRATE TO USE SINGLETON VERSION THEN DELETE
    subscribe({ getters }, payload) {
        getters.pubsub.subscribe(payload.eventName, payload.handlerFn);
    },
    // TODO - MIGRATE TO USE SINGLETON VERSION THEN DELETE
    unsubscribe({ getters }, payload) {
        getters.pubsub.unsubscribe(payload.eventName, payload.handlerFn);
    },
    evalBodyScrollLock({ commit, rootGetters }) {
        if (!isSmallScreen()
            && !isTablet()
            && !rootGetters['device/isMobileOnDesktop']) {
            commit(SET_BODY_SCROLL_LOCK, false);
            return;
        }
        commit(SET_BODY_SCROLL_LOCK, rootGetters['topic/showGraphicTabContent']);
    },
    evalIosBodyHideLock({ commit, rootGetters }) {
        if (!isSmallScreen()
            && !isTablet()
            && !rootGetters['device/isMobileOnDesktop']) {
            commit(SET_IOS_BODY_HIDE_LOCK, false);
            return;
        }
        commit(SET_IOS_BODY_HIDE_LOCK, rootGetters['topic/showGraphicTabContent']);
    },
    // eslint-disable-next-line no-empty-pattern
    logEvent({}, payload) {
        const { eventUrl, eventParams } = payload;
        return utdRestClient.post({
            uri: eventUrl,
            params: eventParams,
            config: { bypassAll: true }
        }).catch(e => {
            Logger.warn(`Could not record event:${eventUrl},exception:${e && e.error && e.error.message}`);
        });
    },
    isTrueResizeEvent({ rootGetters }, payload) {
        const { lastWidth, lastHeight } = payload;
        // Some mobile devices report a 'resize' event from scrolling.
        // As you start to scroll the page down, the address & footer bar reduce in size,
        // causing the height of the viewport to change.
        // This method attempts to detect this,
        // and will return an object containing following properties:
        //
        // isTrueResize: true if it is determined to be a fullresize event, false otherwise
        //     newWidth: new width of viewport (after resize)
        //	  newHeight: new height of viewport (after resize)
        let fullResize = true;

        const devWdt = getDocument().documentElement.clientWidth;
        const devHgt = getDocument().documentElement.clientHeight;

        const difWdt = Math.abs(lastWidth - devWdt);
        const difHgt = Math.abs(lastHeight - devHgt);

        if ((rootGetters['device/browserType'] !== C_BROWSER.TYPE_DESKTOP)
            && (difWdt === 0)
            && (difHgt === 0)) {
            fullResize = false;
        }
        return {
            isTrueResize: fullResize,
            newWidth: devWdt,
            newHeight: devHgt
        };
    },
    // eslint-disable-next-line complexity
    resetUI({ getters, rootGetters, commit }, payload) {
        const { scrollToFixedToolbar } = payload;
        commit(SET_HAS_HEADER, true);
        commit(SET_NO_HEADER_CLASS, '');
        commit(`error/${SET_ERROR_CLASS}`, false, { root: true });
        commit(SET_FIXED_HTML, '');
        commit(`search/${SET_SEARCHBAR_BODY}`, false, { root: true });
        commit(`search/${SET_SEARCHBAR_HEADER}`, true, { root: true });
        commit(`search/${SET_SEARCHBAR_AUTOCOMPLETE}`, '', { root: true });
        commit(`search/${SET_SEARCHBAR_DISABLE_AC}`, false, { root: true });
        commit(`topic/${SET_IS_TOPIC_VIEW}`, false, { root: true });
        commit(`profile/${SET_IS_MY_UTD_VIEW}`, false, { root: true });
        commit(SET_IS_PRINT_VIEW, false);
        commit(`graphic/${SET_IS_GRAPHIC_VIEW}`, false, { root: true });
        commit(`search/${SET_IS_SEARCH_VIEW}`, false, { root: true });
        commit(`qap/${SET_IS_QUICK_ACCESS_PANEL}`, false, { root: true });
        commit(`search/${SET_IS_GRAPHICAL_ANSWERS}`, false, { root: true });
        commit(`search/${SET_IS_GRAPHIC}`, false, { root: true });
        commit(`graphic/${SET_IS_PRINT_GRAPHIC}`, false, { root: true });
        commit(`search/${SET_IS_HOME_SEARCH_EMPTY}`, false, { root: true });
        commit(SET_IS_LANDING_HOME, false);
        commit(SET_BODY_SCROLL_LOCK, false);
        commit(SET_IOS_BODY_HIDE_LOCK, false);
        commit(SET_HAS_PROMOBOX, false);
        commit(SET_DEFAULT_PAGE_LANG, C_LANGUAGES_NAMES.EN.CODE);
        commit('toc/SET_TOC_VIEW', false, { root: true });

        try {
            commit(SET_META_DESCRIPTION, getters.defaultMetaDescription);

            let canonicalUrl = `${
                getWindow().location.origin
            }${
                getWindow().location.pathname
            }`;

            // Images are the only page without SEO friendly URLs
            if (getRelativeUrl().search('/image') > -1
                || getRelativeUrl().search('/image/print') > -1) {
                const imageKey = getQueryParamValues(getWindow().location.href, 'imageKey');
                if (imageKey) {
                    canonicalUrl = setQueryParamValue(canonicalUrl, 'imageKey', imageKey);
                }
            }
            commit(SET_CANONICAL_URL, canonicalUrl);

            // If on desktop browser force page to scroll to top because it isn't fixed panel based
            if (rootGetters['device/browserData'].type === C_BROWSER.TYPE_DESKTOP) {
                const yPos = scrollToFixedToolbar ? C_UI.FIXED_TOOLBAR_POSITION_PX : 0;
                getWindow().scrollTo(0, yPos);
            }

            // Reset positioning of footer
            if (rootGetters['device/browserData'].type === C_BROWSER.TYPE_DESKTOP) {
                const footerEl = document.querySelector('#appContainer > footer');
                if (footerEl) {
                    footerEl.classList.remove('displayed');
                    footerEl.classList.remove('positioned');
                }
            }
        }
        catch (e) {
            Logger.warn(`Error resetting the UI error:${e}`);
        }
    },
    // eslint-disable-next-line complexity
    updateUiHooks({ commit, rootGetters }, _navigator = navigator) {
        // Apply settings required after info change

        // When app initializes, add class vueLoaded to body.
        // Prior to that, index.html has class ngLoading.
        commit(SET_VUE_LOADED, 'vueLoaded');

        // Sets css class if running in iOS standalone mode
        if (_navigator.standalone) {
            commit('device/SET_FULLSCREEN_CLASS', 'iosfullscreen', { root: true });
        }

        // Fixes ios 7 tablet viewport bug
        if (rootGetters['device/browserData'].os === C_BROWSER.OS_APPLE_MOBILE
            && rootGetters['device/browserData'].osVersion.charAt(0) === '7'
            && rootGetters['device/browserData'].type === C_BROWSER.TYPE_TABLET) {
            commit(SET_FIXED_HTML, 'fixedHTML');
            commit(SET_IS_DEFAULT_FIXED, true);
        }

        // Fixes android 4.0 home screen bug
        if (rootGetters['device/browserData'].name.indexOf('Android') > -1) {
            const osVerSub3 = rootGetters['device/browserData'].osVersion.substr(0, 3);
            switch (osVerSub3) {
            case '4.0':
                rootGetters['device/browserData'].customVersion = 'AndroidSub44 Android40';
                break;

            case '4.1':
                rootGetters['device/browserData'].customVersion = 'AndroidSub44 Android41';
                break;

            case '4.2':
                rootGetters['device/browserData'].customVersion = 'AndroidSub44 Android42';
                break;

            case '4.3':
                rootGetters['device/browserData'].customVersion = 'AndroidSub44 Android43';
                break;

            case '4.4':
                rootGetters['device/browserData'].customVersion = 'Android44';
                break;
            }
        }
    },
    openChangeLanguageDialog({ rootGetters, commit, dispatch }, e) {
        e.preventDefault();
        if (rootGetters['device/browserData'].type === C_BROWSER.TYPE_DESKTOP) {
            commit(`user/${SET_DIALOGUE_LANGUAGE}`,
                rootGetters['user/language'], { root: true });
            new ServerMessaging().loadAndDisplayDialog({
                title: 'Search in your own language:',
                component: 'ChooseLanguageDialogTemplate',
                doCompile: true,
                isFullscreen: false,
                buttons: [{
                    label: i18next.t('CANCEL'),
                    action: 'hide',
                    id: 'change-language-cancel',
                    style: 'text'
                }, {
                    label: i18next.t('FORMS.SUBMIT'),
                    action: 'controller',
                    controller: () => {
                        dispatch('user/changeLanguage', '', { root: true });
                    },
                    id: 'change-language-submit'
                }]
            }, true);
        }
        else {
            getWindow().location.href = '/settings/change-language';
        }
    },
    delayExecuteUntilDataLoaded({ getters, commit }, fn) {
        if (getters.isAppDataLoaded) {
            (fn)();
        }
        else {
            commit(ENQUEUE_AFTER_LOAD, fn);
        }
    },
    executePostDataLoadMethods({ getters, commit }) {
        commit(SET_APP_DATA_LOADED);
        if (getters.postDataLoadQueue.length === 0) {
            return;
        }
        for (let i = 0; i < getters.postDataLoadQueue.length; i++) {
            (getters.postDataLoadQueue[i])();
        }
    },
    confirmUserRegistered({ getters, dispatch }) {
        return new Promise(resolve => {
            dispatch('delayExecuteUntilDataLoaded', () => {
                resolve(getters.isUserRegistered);
            });
        });
    },
    // eslint-disable-next-line complexity
    setSessionManagerInfo({ commit, dispatch }, data) {
        if ('userEmail' in data) {
            commit(`profile/${SET_USER_EMAIL}`, data.userEmail, { root: true });
        }

        if ('oidcLoginHint' in data) {
            commit(`profile/${SET_OIDC_LOGIN_HINT}`, data.oidcLoginHint, { root: true });
        }
        if ('isSsoMergeRequest' in data) {
            commit(`profile/${SET_KEEP_ONE_ACCOUNT_REQUEST}`,
                data.isSsoMergeRequest, { root: true });
        }
        commit(SET_ANALYTICS_INFO, data);
        commit(SET_IS_USER_REGISTERED, data);
        commit(SET_UCC_COPYRIGHT_YEAR, data);
        commit(SET_IS_DISASTER_RECOVERY, data);
        commit(SET_COPYRIGHT_HTML);
        commit(`feature/${SET_HAS_CONTENT_SHARE}`, data, { root: true });
        commit(`feature/${SET_LOGIN_REDESIGN_ENABLED}`, data, { root: true });
        commit(`feature/${SET_TWO_FACTOR_EMAIL_ENABLED}`, data, { root: true });
        commit(`feature/${SET_IS_HEADER_REDESIGN}`, data, { root: true });
        commit(`feature/${SET_IS_HOMEPAGE_REDESIGN}`, data, { root: true });
        commit(`login/${SET_RENEWAL_SUBSCRIPTION_URL}`, data, { root: true });
        if ('isUccQuickAccessPanel' in data && !!data.isUccQuickAccessPanel) {
            commit(`qap/${SET_IS_UCC_QUICK_ACCESS_PANEL}`, true, { root: true });
        }

        commit(SET_IS_PROSPECT_CHINA, data);
        commit(SET_ALWAYS_SHOW_BACK_BUTTON, data);

        if ('showSubscribeBox' in data) {
            commit(`login/${SET_LOGIN_SUBSCRIBER_BOX}`, data.showSubscribeBox, { root: true });
        }

        if ('showFormulinkContent' in data) {
            commit(
                `feature/${SET_FORMULINK_CONTENT}`,
                data.showFormulinkContent,
                { root: true }
            );
        }

        if ('showRegisterButton' in data) {
            commit(`login/${SET_LOGIN_REGISTER_BUTTON}`, data.showRegisterButton, { root: true });
        }

        if ('userProfileInfo' in data) {
            dispatch('profile/setUserProfileInfo', data.userProfileInfo, { root: true });
        }

        if ('showSkipLink' in data) {
            commit(`feature/${SET_SHOW_SKIP_LINK}`, data.showSkipLink, { root: true });
        }

        if ('myUpToDatePermission' in data) {
            commit(
                `profile/${SET_MYUPTODATE_PERMISSIONS}`,
                data.myUpToDatePermission,
                { root: true }
            );
        }

        if ('isShowSearchResultAlternate' in data) {
            commit(`search/${SET_SEARCH_RESULT_ALTERNATE}`,
                data.isShowSearchResultAlternate, { root: true });
        }

        if ('infoMessageList' in data) {
            commit(`profile/${SET_MYUPTODATE_MESSAGE}`, data.infoMessageList, { root: true });
        }

        commit('SET_ADDITIVE_CONTENT', data);
        dispatch('injectAdditiveCss');

        if ('welcomeName' in data) {
            commit(`profile/${SET_WELCOME_NAME}`, data.welcomeName, { root: true });
        }

        if ('issueGuestPass' in data) {
            commit(`feature/${SET_GUEST_PASS}`, data.issueGuestPass, { root: true });
        }

        commit(`feature/${SET_TOPIC_REDESIGN}`, data, { root: true });

        if ('fontSize' in data) {
            commit(`topic/${SET_FONT_SIZE_PREFERENCE}`, data.fontSize, { root: true });
        }
        dispatch('topic/setTopicFontSizeBodyClass', undefined, { root: true });

        commit(SET_PATHWAYS_CONTENT_SETS, data);
        commit(`feature/${SET_SHOW_LAB_INTERPRETATION}`, data, { root: true });
        commit(`feature/${SET_SHOW_RX_TRANSITIONS}`, data, { root: true });
        commit(`cme/${SET_CME_AUTO_SUBMIT}`, data, { root: true });
        commit(`auth/${SET_SSO_ACCOUNT_NAME}`, data, { root: true });
        commit(`search/${SET_SEARCH_RESULTS_STATE}`, data, { root: true });
        commit(`cme/${SET_MOC_BOARD_URLS}`, data, { root: true });
        commit(`auth/${SET_ODIC_PARTNER_DETAILS}`, data, { root: true });
        commit(`auth/${SET_EXPIRED_SITE}`, data, { root: true });
        commit(`user/${SET_EXPIRATION_STATUS}`, data, { root: true });
        commit(`login/${SET_LOGIN_ERROR_MESSAGE}`, data, { root: true });
    },
    injectAdditiveCss({ getters }) {
        const styleEl = getDocument().querySelector('head #additiveContentCss');
        if (styleEl) {
            return;
        }

        if (getters.additiveContentCss) {
            const style = getDocument().createElement('style');
            style.id = 'additiveContentCss';
            style.innerText = getters.additiveContentCss;
            getDocument().head.appendChild(style);
        }
    },
    setNoHeaderState({ commit }) {
        commit(SET_HAS_HEADER, false);
        commit(`search/${SET_SEARCHBAR_BODY}`, false);
        commit(`search/${SET_SEARCHBAR_HEADER}`, false);
        commit(SET_NO_HEADER_CLASS, 'noHeader');
    },
    checkLastContentUpdate({ commit, state, dispatch }, lastContentUpdate) {
        if (typeof lastContentUpdate === 'undefined') {
            return;
        }

        let prevLastContentUpdate = state.lastContentUpdate;
        const isFirstCheckOfSession = prevLastContentUpdate === NULL_LAST_CONTENT_UPDATE;

        // If user has lastContentUpdate in their localStorage, then use that
        // rather than NULL_LAST_CONTENT_UPDATE; otherwise page refresh would
        // always result in a cleared cache. if user has "cookies" disabled
        // (meaning localStorage), then this is moot, but clearing the cache
        // still makes sense to do to guarantee consistency.
        if (isFirstCheckOfSession) {
            prevLastContentUpdate = getLastContentUpdateFromLocalStorage();
            commit(SET_LAST_CONTENT_UPDATE, prevLastContentUpdate);
        }

        // Even if the server's timestamp is somehow older (theoretical edge
        // case), its content is the single source of truth, so clear cache if
        // value is different. We need to clear the cache even if
        // isFirstCheckOfSession is true, since the cache persists between
        // sessions.
        if (prevLastContentUpdate !== lastContentUpdate) {
            commit(SET_LAST_CONTENT_UPDATE, lastContentUpdate);
            updateLocalStorageWithLastContentUpdate(lastContentUpdate);
            dispatch('topic/onContentWasUpdated', '', { root: true });
        }
    },
    setupGA4({ getters, commit }) {
        // Set up gtag.js (support for GA4)
        if (!getters.isOneTrustEnabled
            || getters.hasInitializedGTag
            || !getters.googleAnalytics.isRecord) {
            return;
        }
        const googleGA4TagId = getters.googleAnalytics.info.tagIdGA4;
        if (googleGA4TagId === null) {
            return;
        }
        setupGoogleAnalytics4(googleGA4TagId);
        commit(HAS_INITIALIZED_GTAG, true);
    },
    openBookmarkedMyAccountSidebar({ commit }, url) {
        if (getQueryParamValues(url, C_MY_ACCOUNT.MY_ACCOUNT_QUERY_PARAM)) {
            commit(SET_MYACCOUNT_SIDEBAR_REQUEST, C_MY_ACCOUNT.SIDEBAR_REQUEST.APPROVED);
        }
    }
};

export const getters = {
    // Misc
    isDisasterRecovery: state => state.isDisasterRecovery,
    router: state => state.router,
    pubsub: state => state.pubsub,
    transitions: state => state.transitions,
    copyrightHtmlFull: state => state.copyright.full,
    copyrightHtmlUcc: state => state.copyright.ucc,
    uccCopyrightYear: state => state.uccCopyrightYear,
    lastUrlLoaded: state => state.lastUrlLoaded,
    disabledPathways: state => state.disabledPathways,
    enabledPathways: state => state.enabledPathways,
    additiveContentKeys: state => state.additiveContent.keys,
    additiveContentClasses: state => state.additiveContent.classes,
    additiveContentCss: state => state.additiveContent.css,
    storedSetting: state => key => state.storedSettings[key],
    metaDescription: state => state.metaDescription,
    defaultMetaDescription: state => state.defaultMetaDescription,
    fixedHTML: state => state.fixedHTML,
    defaultPageLang: state => state.defaultPageLang,
    contentVersion: state => state.product.contentVersion,
    releaseVersion: state => state.product.releaseVersion,
    applicationVersion: state => state.product.applicationVersion,
    countryCode: state => state.product.countryCode,

    // *Services
    utdAuthService: state => state.utdAuthService,
    tooltipConfig: (state, getters, rootState, rootGetters) => {
        return {
            supportsTippy: rootState.device.browser.isSupported.tippy,
            isPrintView: state.isPrintView,
            isDesktopView: () => rootGetters['device/isDesktopView']
        };
    },

    // Booleans
    isAlwaysShowBackButton: state => state.isAlwaysShowBackButton,
    isUccState: state => state.isUccState,
    isLandingHome: state => state.isLandingHome,
    isDesktopBrowser: (state, getters, rootState) => {
        return rootState.device.browser.type === C_BROWSER.TYPE_DESKTOP;
    },
    isProspectView: (state, getters, rootState, rootGetters) => {
        return !rootGetters['user/isCustomer']
            && !rootState.profile.myUpToDate.permissions.myAccount
            && state.isAppDataLoaded
            && !rootGetters['device/isMobileOnDesktop']
            && rootGetters['device/browserType'] === C_BROWSER.TYPE_DESKTOP;
    },
    isProspectMode: (state, getters, rootState, rootGetters) => {
        return !rootGetters['user/isCustomer']
            && !rootState.profile.myUpToDate.permissions.myAccount
            && state.isAppDataLoaded;
    },
    isChinaProspectView: (state, getters) => {
        return getters.isProspectView
            && (state.isProspectChina
                || state.isUccState);
    },
    isAnonymousUser: (state, getters) => {
        return getters.isProspectMode
            || getters.isAuthenticatedByIp;
    },
    isTabletDevice: (state, getters, rootState) => {
        return rootState.device.browser.type === C_BROWSER.TYPE_TABLET;
    },
    isAuthenticatedByIp: (state, getters, rootState, rootGetters) => {
        return !rootGetters['user/userLoggedIn'] && rootGetters['user/isCustomer'];
    },
    isLapsedUser: (state, getters, rootState, rootGetters) => {
        return rootGetters['user/userLoggedIn'] && !rootGetters['user/isCustomer'];
    },
    isOidcSearchLanding: (state, getters, rootState, rootGetters) => {
        return isOidcSearchLanding(state, getters, rootGetters);
    },
    isSpinnerVisible: state => state.isSpinnerVisible,
    hideForOidcPartner: (state, getters) => hideForOidcPartner(state, getters),
    showWkFooter: (state, getters, rootState, rootGetters) => {
        return !rootState.footer.isFooterHidden
            && rootGetters['feature/isHeaderRedesign']
            && (!rootGetters['feature/hideNavBar']
                || rootGetters['device/isDesktopView'])
            && !(getters.getOidcPartnerName
                && (getters.routeLoading === 'login'
                    || getters.routeLoading === 'resetPassword'));
    },
    routeLoading: state => state.routeLoading,
    isMobileSafari: (state, getters, rootState) => {
        return rootState.device.browser.os === C_BROWSER.OS_APPLE_MOBILE
            && rootState.device.browser.name === 'MobileSafari';
    },
    isCobrandingEnabled: state => state.cobranding.enabled,
    cobrandingImageUrl: state => state.cobranding.imageUrl,
    cobrandingAnchorHref: state => state.cobranding.anchorHref,
    isAppDataLoaded: state => state.isAppDataLoaded,
    postDataLoadQueue: state => state.executeAfterLoad,
    googleAnalytics: state => state.googleAnalytics,
    isUserRegistered: state => state.isUserRegistered,
    getDestinationUrl: state => state.destinationUrl,
    routePathname: state => state.routePathname,
    isProspectChina: state => state.isProspectChina,
    isDefaultFixed: state => state.isDefaultFixed,
    canonicalUrl: state => state.canonicalUrl,
    noHeaderClass: state => state.noHeaderClass,
    utdLogoFilename: state => state.utdLogoFilename,
    isFixedToolbar: state => state.isFixedToolbar,
    hasHeader: state => state.hasHeader,
    lockBodyScroll: state => state.lockBodyScroll,
    iosBodyLockHide: state => state.iosBodyLockHide,
    isPrintView: state => state.isPrintView,
    isOneTrustEnabled: state => state.isOneTrustEnabled,
    clickEventUrls: state => state.clickEventUrls,
    printData: state => state.printData,
    isFixedSidebar: state => state.isFixedSidebar,
    hasPromoBox: state => state.hasPromoBox,
    productName: state => state.product.name,
    hasSessionData: state => state.hasSessionData,
    vueLoaded: state => state.vueLoaded,
    isContentRefreshed: state => state.isContentRefreshed,
    utdGxGenStyles: state => state.cssStyleSheets.utdGxGen,
    medCalcStyles: state => state.cssStyleSheets.medCalc,
    ankiDownloadTemplate: state => state.ankiDownloadTemplate,
    hasInitializedGTag: state => state.hasInitializedGTag,
    myAccountSidebarRequestStatus: state => state.myAccountSidebarRequestStatus
};

const app = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};

export default app;

function isOidcSearchLanding(state, getters, rootGetters) {
    return getters.getOidcPartnerName
        && state.routeLoading === 'search'
        && rootGetters['search/isHomeSearchEmpty'];
}

function hideForOidcPartner(state, getters) {
    return getters.getOidcPartnerName
        && getters.isAuthenticatedByIp
        && state.routeLoading !== 'search'
        && state.routeLoading !== 'topic';
}

function getLastContentUpdateFromLocalStorage() {
    const utdCache = new UtdCache();

    try {
        if (utdCache.cookiesDisabled) {
            return NULL_LAST_CONTENT_UPDATE;
        }
        const lastUpdateFromCache = utdCache.getPersistent('utdSPAlastContentUpdate');
        const lastUpdateFromCacheNum = parseFloat(lastUpdateFromCache) || NULL_LAST_CONTENT_UPDATE;
        return Math.max(NULL_LAST_CONTENT_UPDATE, lastUpdateFromCacheNum);
    }
    catch (error) {
        return NULL_LAST_CONTENT_UPDATE;
    }
}

function updateLocalStorageWithLastContentUpdate(lastContentUpdate) {
    const utdCache = new UtdCache();

    if (!utdCache.cookiesDisabled) {
        utdCache.setPersistent('utdSPAlastContentUpdate', lastContentUpdate, 365);
    }
}

function setupGoogleAnalytics4(tagId) {
    const src = `https://www.googletagmanager.com/gtag/js?id=${tagId}`;
    getWindow().OneTrust.InsertScript(src, 'head', null, null, PERF_COOKIE_ONETRUST_GRP_ID);
    if (!isOneTrustInjectedGA4(src)) {
        // User probably declined cookies, so do not proceed.
        return;
    }

    // A port of Google's basic gtag script that uses getWindow
    // https://developers.google.com/tag-platform/gtagjs/install

    // eslint-disable-next-line max-len
    // Note: We followed suggestion 1 from https://developers.google.com/analytics/devguides/migration/measurement/add-ga4?technology=analyticsjs
    // This decision may need to be verified against the alternatives.
    const win = getWindow();
    win.dataLayer = win.dataLayer || [];
    win.gtag = function gtag() {
        // eslint-disable-next-line prefer-rest-params
        win.dataLayer.push(arguments);
    };
    win.gtag('js', new Date());

    win.gtag('config', tagId);
}

function isOneTrustInjectedGA4(src) {
    return getDocument().querySelector(`script[src="${src}"]`);
}